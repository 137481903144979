import React from 'react';
import PropTypes from 'prop-types';

import styles from './Container.module.scss';

// Max width container
const Container = ({ children, element }) => {
  const Tag = element;
  return <Tag className={styles.container}>{children}</Tag>;
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  element: PropTypes.oneOf(['div', 'section', 'footer']),
};

Container.defaultProps = {
  element: 'div',
};

export default Container;
